import React from "react"

const defaultStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

export default ({children, style, ...props}) =>
    <div style={{...defaultStyle, ...style}} {...props}>{children}</div>
