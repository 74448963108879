import React from 'react'

import { rhythm } from '@/utils/typography'
import Centered from '@/components/Centered'

import { useInView } from 'react-intersection-observer'

const container = {
    padding: rhythm(1),
    marginBottom: rhythm(1),
    marginTop: rhythm(1/2),
}

const videoStyle = {
    borderRadius: rhythm(1/2),
    boxShadow: '2px 1px lightgray',
    maxWidth: '100%',
    marginBottom: rhythm(1/4)
}

const VideoContainer = ({children, style}) =>
    <Centered>
        <div style={{...container, ...style}}>
            {children}
        </div>
    </Centered>

export const Vid = ({style, ...props}) => {
    const { ref, inView, entry } = useInView({threshold: 0.5})

    if (inView) {
        props['autoPlay'] = true
        props['muted'] = true
    }

    let key = `${props.src}__${inView}`
        // force new element to actually start playing
        // setting autoplay attribute after element is created doesn't start play

    return <video key={key} ref={ref} controls loop style={{...videoStyle, ...style}} {...props} />
}

export const VideoIframe = ({style, ...props}) =>
    <VideoContainer>
        <iframe style={{...videoStyle, ...style}} {...props} />
    </VideoContainer>

const Video = ({style, ...props}) =>
    <VideoContainer>
        <Vid style={style} {...props} />
    </VideoContainer>

export default Video
